<template>
  <div class=".box">
    <div class="header">
      <img class="img1" src="@/assets/img/shouye.png" alt="">
      <img class="img2" src="@/assets/img/logo.png" alt="" />
      <div class="img3" id="img3">
        <!-- <img src=@/assets/img/code.png alt=""> -->
        <vue-qr :logoSrc="logoSrc" :text="url" :size="300" :margin="15"></vue-qr>
      </div>
      <!-- <img class="img3" src="@/assets/img/erweima.png" alt="" /> -->
    </div>
    <div class="middle1">
      <div class="con">
        <img class="img1" src="@/assets/img/chanpingjieshao.png" alt="" />
        <img class="img2" src="@/assets/img/2_neirong.png" alt="" />
      </div>
    </div>
    <div class="middle2">
      <div class="con">
        <img src="@/assets/img/3_neirong.png" alt="" />
      </div>
    </div>
    <div class="middle3">
      <div class="con">
        <img src="@/assets/img/4_neirong.png" alt="" />
      </div>
    </div>
    <div class="middle4">
      <div class="con">
        <img class="img1" src="@/assets/img/5_neirong.png" alt="" />
        <div class="img2" id="img2">
          <!-- <img src="@/assets/img/code.png" alt=""> -->
          <vue-qr :logoSrc="logoSrc" :text="url" :size="300" :margin="15"></vue-qr>
        </div>
      </div>
    </div>
    <div class="footer">
      <p>
        2021©xiyou www.xiyou.letuinet.com | 陕ICP备17001630号 |
        西安乐推网络科技有限公司 All Rights Reserved. 中国互联网举报中心 |
        违法和不良信息举报：400-800-3692
      </p>
    </div>
  </div>
</template>

<script>
import vueQr from 'vue-qr'

export default {
  name: "Home",
  components: {vueQr},
  data() {
    return {
      url: (window.location.href.indexOf('test.')==-1 && window.location.href.indexOf('localhost')==-1)?"https://xiyou.letuinet.com/":"https://test.xiyou.letuinet.com/",
      logoSrc: require('@/assets/img/logo_icon.png'),
    };
  },
};
</script>
<style scoped>
.box {
  width: 100%;
  font-size: 0;
}
.header{
  position: relative;
  font-size: 0;
}
.header .img1{
  width: 100%;
}
.header .img2{
  width: 11.15vw;
  position: absolute;
  top: 0px;
  left: 18.75vw;
}
.header .img3{
  width: 10.57vw;
  height: 10.739vw;
  background-image: url("~@/assets/img/erweima.png");
  background-size: 100%;
  position: absolute;
  top: 38.5vw;
  left: 70.9vw;
}
.header .img3 img{
  width: 10vw;
  padding: 3px;
}
.con{
  width: 62.5vw;
  margin:0px auto;
  text-align: center;
  font-size: 0;
}
.middle1{
   background-image: url("~@/assets/img/2_beijing.png");
}
.middle1,.middle3{
  width: 100%;
  background-size: 100%;
  overflow: hidden;
}
.middle3{
   background-image: url("~@/assets/img/4_beijing.png");
}
.middle1 .img1{
  margin-top:69px ;
  width: 379px;
}
.middle1 .img2{
  width: 100%;
}
.middle2,.middle4{
  width: 100%;
  background-image:url("~@/assets/img/3_beijing.png");
  background-size: 100%;
}
.middle2 img{
  width: 100%;
  margin-top: 5.89vw;
}
.middle3 img{
  width: 100%;
  margin-top: 6.75vw;
}
.middle4 .img1{
  width: 100%;
  margin-top: 8.15vw;
}
.middle4{
  position: relative;
}
.middle4 .img2{
  width: 22.6vw;
  height: 12.396vw;
  background-image: url("~@/assets/img/dibu_erweima.png");
  background-size: 100%;
  position: absolute;
  top: 43.2vw;
  left: 58.65vw;
}
.middle4 .img2 img{
  width: 10.2vw;
  float: left;
  margin: 1.7vw 0px 0px .13vw;
}
.footer {
  width: 100%;
  padding: 42px 0;
}
.footer p {
  width: 650px;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #222222;
  line-height: 30px;
  margin: auto;
  text-align: center;
}
</style>
