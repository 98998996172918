import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: "/moblie/:lang?",
    name: "moblie",
    component: () => import('../views/Moblie.vue')
  },
  {
      path: "/agree/:lang?",
      name: "agree",
      component: () => import('../views/Agree.vue')
  },
  {
      path: "/privacy/:lang?",
      name: "privacy",
      component: () => import('../views/Privacy.vue')
  },
  {
    path: "/wxagree",
    name: 'wxagree',
    component: ()=>import('../views/WxAgree.vue')
  },
  {
    path: "/wxprivacy",
    name: 'wxprivacy',
    component: () => import('../views/WxPrivacy.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
