<template>
  <div class="home">
    <div class="pc">
      <Pc></Pc>
    </div>
    <div class="mobile">
      <Moblie></Moblie>
    </div>
  </div>
</template>

<script>
import Pc from './Pc.vue'
import Moblie from './Moblie.vue'
  export default {
    components:{
      Pc,
      Moblie
    }
  }
</script>

<style lang="scss" scoped>
  @media only screen and (min-width: 900px) {
  .pc {
    display: block !important;
  }
  .mobile {
    display: none !important;
    touch-action: auto !important;;
  }
}
// 手机端
@media only screen and (max-width: 899px) {
  .pc {
    display: none !important;
  }
  .mobile {
    display: block !important;
    touch-action: auto !important;
  }
}
</style>