<template>
  <div class=".box">
    <div class="header">
      <img class="img1" src="@/assets/images/logo.png" alt="" />
      <img class="img2" src="@/assets/images/shouye_neirong.png" alt="" />
      <!-- <a href="https://apps.apple.com/us/app/%E6%94%BE%E7%BD%AE%E8%A5%BF%E6%B8%B8-%E6%8C%82%E6%9C%BA%E7%AD%96%E7%95%A5%E5%8D%95%E6%9C%BA%E4%BF%AE%E7%9C%9F/id1596074348?l=zh"><img class="img3" src="@/assets/images/button.png" alt="" /></a> -->
      <a :href="url"><img class="img3" src="@/assets/images/button.png" alt="" /></a>
    </div>
    <div class="middle1">
      <img class="img1" src="@/assets/images/chanpingjieshao.png" alt="" />
      <img class="img2" src="@/assets/images/2_neirong.png" alt="" />
    </div>
    <div class="middle2">
      <img src="@/assets/images/3_neirong.png" alt="" />
    </div>
    <div class="middle3">
      <img src="@/assets/images/4_neirong.png" alt="" />
    </div>
    <div class="middle4">
      <img class="img1" src="@/assets/images/5_neirong.png" alt="" />
    </div>
    <div class="footer">
      <p>
        2021©xiyou www.xiyou.letuinet.com | 陕ICP备17001630号 |
      西安乐推网络科技有限公司 All Rights Reserved. 中国互联网举报中心 |
      违法和不良信息举报：400-800-3692
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Mobile",
data() {
    return {
      url: "https://adminapi.xiyou.letuinet.com/app/download",
    };
  },
  methods: {
    getUrl(apiUrl){
      this.$axios(
        {
          url: apiUrl,
          method: 'post',
        }
      ).then(res=>{
        console.log(res);
        if(res.data.code==0){
          console.log(res.data.data.url)
          this.url = res.data.data.url;
        }
      })
    },
  },
  mounted(){
    if(window.location.href.indexOf('test.')==-1 && window.location.href.indexOf('localhost')==-1){
      // 正式
      this.getUrl("https://adminapi.xiyou.letuinet.com/download/url")
    }else{
      // 测试
      this.getUrl("https://adminapi.test.xiyou.letuinet.com/download/url")
    }
    
  }
};
</script>

<style lang="scss" scoped>
.box {
  width: 100%;
}
.header {
  width: 100%;
  background-color: #ffef30;
}
.header .img1 {
  position: absolute;
  width: 1.07rem;
  left: .265rem;
  top: 0rem;
}
.header .img3 {
  position: absolute;
}
.header .img2 {
  width: 100%;
  margin: .16rem 0rem;

}
.header .img3 {
  width: 2.3rem;
  top: 6.28rem;
  left: .94rem;
}
.middle1,
.middle2,
.middle3,
.middle4 {
  width: 100%;
  position: relative;
}
.middle1 {
  background-image: url("~@/assets/images/2_beijing.png");
  background-size: 100% 100%;
  height: 3.4rem;
}
.middle1 .img1 {
  width: 1.595rem;
  position: absolute;
  top: 0.215rem;
  left: 50%;
  transform: translateX(-50%);
}
.middle1 .img2 {
  width: 3.85rem;
  height: 2.62rem;
  position: absolute;
  top: .785rem;
  left: 50%;
  transform: translateX(-50%);
}
.middle2 {
  height: 3.375rem;
}
.middle2,
.middle4 {
  background-image: url("~@/assets/images/3_beijing.png");
  background-size: 100% 100%;
}
.middle2 img,
.middle3 img,
.middle4 img {
  width: 3.85rem;
  height: 2.995rem;
  position: absolute;
  top: .385rem;
  left: 50%;
  transform: translateX(-50%);
}
.middle3,
.middle4 {
  height: 3.37rem;
}
.middle3 {
  background-image: url("~@/assets/images/4_beijing.png");
  background-size: 100% 100%;
}
.footer{
  width: 100%;
  padding: .21rem 0rem;
}
.footer p{
  width: 3.1rem;
  font-size: .07rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #222222;
  line-height: .15rem;
  margin: auto;
  text-align: center;
}
</style>
